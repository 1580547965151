import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ColumnDefinition, Options } from "tabulator-tables";
import { Grid } from "../../components/Grid";
import { useFormatter } from "../../hooks/useFormatter";
import { useRouter } from "../../hooks/useRouter";
import { deepClone } from "../../trendrating/deepClone";
import { paths } from "../../types/Defaults";
import { ProductInfo } from "../../types/Product";
import { getOrderedKeys } from "../ProductList";
import { PTag } from "../../trendrating/api/PTag";

type ProductListItemsLSProps = {
  filter: any;
  products: ProductInfo[];
};

export const ProductListTableItemsLS = ({
  filter,
  products,
}: ProductListItemsLSProps) => {
  const formatter = useFormatter();
  const { t /*, i18n */ } = useTranslation();
  const { history } = useRouter();
  // Find maximum row height
  // const totalRows = useMemo(
  //     () =>
  //         products.reduce(
  //             (maxLength: number, item: ProductInfo) =>
  //                 Math.max(Object.keys(item.description).length, maxLength),
  //             0
  //         ),
  //     [products]
  // );

  // const callback = useCallback((event: any) => {
  //     console.log(event);
  // }, []);

  // TODO refactor and remove it
  // Prepare flat list used for rendering
  // Has objects of objects (geo -> theme -> method)
  // If positions
  const groupedProducts: any = useMemo(() => {
    if (products == null) {
      return {};
    }

    const groupedProducts: any = {};

    /**
     * Data structure:
     *
     * <geo>: {
     *     <theme>: {
     *          <method>: {
     *              single: <item>
     *              30: <item>,...
     *          }
     *     }
     * }
     *
     */

    // Todo can set only the key instead of the full object because
    // there is also the other "products" array with full data.

    let sortedProducts = deepClone(products);
    sortedProducts.sort((a: any, b: any) => {
      let exposureA = a.exposure ?? Infinity;
      let exposureB = b.exposure ?? Infinity;
      if (exposureA > exposureB) {
        return -1;
      } else if (exposureA < exposureB) {
        return 1;
      } else {
        return 0;
      }
    });

    for (let product of sortedProducts) {
      const geo = String(product.geo);
      const theme = String(product.theme);
      const method = String(product.method);
      const position =
        product.positions !== false
          ? String(product.positions)
          : product.positions;
      if (groupedProducts[geo] == null) {
        groupedProducts[geo] = {};
      }

      if (groupedProducts[geo][theme] == null) {
        groupedProducts![geo][theme] = {};
      }
      // if (
      //                 rules != null &&
      //                 product.info.rules != null &&
      //                 rules[product.info.rules] != null
      //             ) {
      //                 products[geo][theme].rules = [...rules[product.info.rules]];
      //                 for (
      //                     let i = rules[product.info.rules].length;
      //                     i < maxRulesLength;
      //                     i++
      //                 ) {
      //                     products[geo][theme].rules.push("");
      //                 }
      //             }

      if (groupedProducts[geo][theme][method] == null) {
        groupedProducts![geo][theme][method] = [];
      }

      groupedProducts![geo][theme][method].push({
        position: position,
        product: product,
      });
    }

    return groupedProducts;
  }, [products]);

  const renderedList = useMemo(() => {
    if (groupedProducts == null) {
      return null;
    }
    const list: any = [];
    getOrderedKeys(groupedProducts, "geo").forEach((geo) =>
      getOrderedKeys(groupedProducts[geo], "theme").forEach((theme) =>
        getOrderedKeys(groupedProducts[geo][theme], "method").forEach(
          (method) => {
            // groupedProducts[geo][theme][method] does contain
            // an array, like this:
            //
            // {position: ..., product: ...}
            // Position can be "false", or a number (as string) of
            // stocks.

            // Iterate all elements inside method to categorize
            // of "single item" or grouped stocks
            // Single items are without number of stocks, displayed
            // as a single box.

            let analytics = null;

            for (const item of groupedProducts[geo][theme][method]) {
              let newItem = deepClone(item.product);

              let isVisible = true;
              if (filter?.geo?.length > 0) {
                isVisible = isVisible && filter.geo.includes(newItem.geo);
              }
              if (filter?.theme?.length > 0) {
                isVisible = isVisible && filter.theme.includes(newItem.theme);
              }
              if (filter?.method?.length > 0) {
                isVisible = isVisible && filter.method.includes(newItem.method);
              }

              if (!isVisible) {
                continue;
              }

              analytics = new PTag(item.product.strategyAnalytics);

              newItem.type = "Stock"; // Used for formatter purposes: the formatter needs this
              // Flattened performances
              // // newItem.performanceEquity1Y =
              // //   newItem?.analytics?.aggregated?.total?.equity?.performances
              // //     ?.YEARLY ?? null;
              // // newItem.performanceEquity3Y =
              // //   newItem?.analytics?.aggregated?.total?.equity?.performances
              // //     ?.YEARLY_3_N ?? null;
              // // newItem.performanceEquity5Y =
              // //   newItem?.analytics?.aggregated?.total?.equity?.performances
              // //     ?.YEARLY_5_N ?? null;

              // // newItem.performanceBenchmark1Y =
              // //   newItem?.analytics?.aggregated?.total?.benchmark?.performances
              // //     ?.YEARLY ?? null;
              // // newItem.performanceBenchmark3Y =
              // //   newItem?.analytics?.aggregated?.total?.benchmark?.performances
              // //     ?.YEARLY_3_N ?? null;
              // // newItem.performanceBenchmark5Y =
              // //   newItem?.analytics?.aggregated?.total?.benchmark?.performances
              // //     ?.YEARLY_5_N ?? null;

              // // newItem.performanceDiff1Y =
              // //   newItem?.analytics?.aggregated?.total?.diff?.performances
              // //     ?.YEARLY ?? null;
              // // newItem.performanceDiff3Y =
              // //   newItem?.analytics?.aggregated?.total?.diff?.performances
              // //     ?.YEARLY_3_N ?? null;
              // // newItem.performanceDiff5Y =
              // //   newItem?.analytics?.aggregated?.total?.diff?.performances
              // //     ?.YEARLY_5_N ?? null;

              // // newItem.annualizedRateOfReturnEquity =
              // //   newItem?.analytics?.aggregated?.total?.equity?.AnnualizedRateOfReturn;
              // // newItem.annualizedRateOfReturnBenchmark =
              // //   newItem?.analytics?.aggregated?.total?.benchmark?.AnnualizedRateOfReturn;
              // // newItem.annualizedRateOfReturnDiff =
              // //   newItem?.analytics?.aggregated?.total?.diff?.AnnualizedRateOfReturn;

              newItem.performanceEquity1Y = analytics.get(
                "performance_equity_1_Y",
                "H"
              );
              newItem.performanceEquity3Y = analytics.get(
                "performance_equity_3_Y",
                "H"
              );
              newItem.performanceEquity5Y = analytics.get(
                "performance_equity_5_Y",
                "H"
              );
              newItem.performanceBenchmark1Y = analytics.get(
                "performance_equity_1_Y",
                "B"
              );
              newItem.performanceBenchmark3Y = analytics.get(
                "performance_equity_3_Y",
                "B"
              );
              newItem.performanceBenchmark5Y = analytics.get(
                "performance_equity_5_Y",
                "B"
              );
              newItem.performanceDiff1Y = analytics.get(
                "performance_equity_1_Y",
                "D"
              );
              newItem.performanceDiff3Y = analytics.get(
                "performance_equity_3_Y",
                "D"
              );
              newItem.performanceDiff5Y = analytics.get(
                "performance_equity_5_Y",
                "D"
              );
              newItem.annualizedRateOfReturnEquity = analytics.get(
                "annualized_rate_of_Return_equity",
                "H"
              );
              newItem.annualizedRateOfReturnBenchmark = analytics.get(
                "annualized_rate_of_Return_equity",
                "B"
              );
              newItem.annualizedRateOfReturnDiff = analytics.get(
                "annualized_rate_of_Return_equity",
                "D"
              );
              list.push(newItem);
            }
          }
        )
      )
    );
    return list;
  }, [filter, groupedProducts]);

  const columns: ColumnDefinition[] = useMemo(() => {
    // const formatPerformance = (value: any) => {
    //     return formatter.custom("number", {
    //         options: {
    //             hasPositiveSign: true,
    //             isPercentage: true,
    //             notAvailable: {
    //                 input: null,
    //                 output: "",
    //             },
    //         },
    //         output: "HTML",
    //         value: value,
    //         valueHelper: null,
    //     });
    // };

    const formatPerformanceColored = (value: any) => {
      return formatter.custom("number", {
        options: {
          colored: "positive",
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    };

    return [
      {
        // hozAlign: "right",
        // vertAlign: "bottom",
        field: "name",
        title: t("Name"),
        widthGrow: 2,
        formatter: (cell, formatterParams, onRendered) => {
          const value: any = cell.getValue();
          const data: any = cell.getData();
          let name = value;
          if (data.positions) {
            name += " - " + data.positions + " Stocks";
          }
          if (data.geo) {
            name = `<span class='inline-flag flag-${data.geo}'></span> ` + name;
          }
          return name;
        },
      },
      {
        hozAlign: "right",
        headerHozAlign: "right",
        // vertAlign: "bottom",
        field: "performanceEquity1Y",
        title: t("1 Year Performance"),
        headerSortStartingDir: "desc",
        formatter: (cell, formatterParams, onRendered) =>
          formatPerformanceColored(cell.getValue()),
        sorter: "number",
        sorterParams: {
          thousandSeparator: ",",
          decimalSeperator: ",",
          alignEmptyValues: "bottom",
        } as any,
      },
      // {
      //     // hozAlign: "right",
      //     // vertAlign: "bottom",
      //     field: "performanceDiff1Y",
      //     title: t("Diff. 1 Year"),
      //     headerSortStartingDir: "desc",

      //     formatter: (cell, formatterParams, onRendered) =>
      //         formatPerformance(cell.getValue()),
      //     sorter: "number",
      //     sorterParams: {
      //         thousandSeparator: ",",
      //         decimalSeperator: ",",
      //         alignEmptyValues: "bottom",
      //     } as any,
      // },

      {
        hozAlign: "right",
        headerHozAlign: "right",
        field: "performanceEquity3Y",
        title:
          t("3 Years Performance") +
          "<br/><span style='font-size: 0.8em'>Annualized</span>",
        headerSortStartingDir: "desc",

        formatter: (cell, formatterParams, onRendered) =>
          formatPerformanceColored(cell.getValue()),
        sorter: "number",
        sorterParams: {
          thousandSeparator: ",",
          decimalSeperator: ",",
          alignEmptyValues: "bottom",
        } as any,
      },
      // {
      //     // hozAlign: "right",
      //     // vertAlign: "bottom",
      //     field: "performanceDiff3Y",
      //     title: t("Diff. 3 Years*"),
      //     headerSortStartingDir: "desc",

      //     formatter: (cell, formatterParams, onRendered) =>
      //         formatPerformance(cell.getValue()),
      //     sorter: "number",
      //     sorterParams: {
      //         thousandSeparator: ",",
      //         decimalSeperator: ",",
      //         alignEmptyValues: "bottom",
      //     } as any,
      // },

      {
        hozAlign: "right",
        headerHozAlign: "right",
        // vertAlign: "bottom",
        field: "performanceEquity5Y",
        title:
          t("5 Years Performance") +
          "<br/><span style='font-size: 0.8em'>Annualized</span>",
        headerSortStartingDir: "desc",
        formatter: (cell, formatterParams, onRendered) =>
          formatPerformanceColored(cell.getValue()),
        sorter: "number",
        sorterParams: {
          thousandSeparator: ",",
          decimalSeperator: ",",
          alignEmptyValues: "bottom",
        } as any,
      },

      {
        hozAlign: "right",
        headerHozAlign: "right",
        // vertAlign: "bottom",
        field: "annualizedRateOfReturnEquity",
        title:
          "10 Years Performance <br/><span style='font-size: 0.8em'>Annualized</span>",
        headerSortStartingDir: "desc",
        formatter: (cell, formatterParams, onRendered) =>
          formatPerformanceColored(cell.getValue()),
        sorter: "number",
        sorterParams: {
          thousandSeparator: ",",
          decimalSeperator: ",",
          alignEmptyValues: "bottom",
        } as any,
      },
      // {
      //     // hozAlign: "right",
      //     // vertAlign: "bottom",
      //     field: "performanceDiff5Y",
      //     title: t("Diff. 5 Years*"),
      //     headerSortStartingDir: "desc",

      //     formatter: (cell, formatterParams, onRendered) =>
      //         formatPerformance(cell.getValue()),
      //     sorter: "number",
      //     sorterParams: {
      //         thousandSeparator: ",",
      //         decimalSeperator: ",",
      //         alignEmptyValues: "bottom",
      //     } as any,
      // },
    ];
  }, [formatter, t]);

  // const preparedColumns = useMemo(
  //     () =>
  //         columns.map<ColumnDefinition>((column: ColumnDefinition) => ({
  //             sorter: "string",
  //             formatter: function (cell, formatterParams, onRendered) {
  //                 return formatter.table(
  //                     cell.getField(),
  //                     "table",
  //                     cell.getData()
  //                 );
  //             },
  //             ...column,
  //         })),
  //     [columns, formatter]
  // );

  const options: Options = useMemo(
    () => ({
      columnHeaderVertAlign: "middle",
      layout: "fitColumns",
      rowClick: (e, row) => {
        row.toggleSelect(); //toggle row selected state on row click
        e.stopPropagation();

        // Navigate to the correct element
        const data = row.getData();
        if (data.id) {
          const link = paths.productDetail.replace(":id", data.id);
          history.push(link);
        }
      },
      initialSort: [{ column: "performanceEquity1Y", dir: "desc" }],
      selectable: 1,
    }),
    [history]
  );

  return (
    <Row className="mt-4">
      <Col sm={12}>
        <Grid
          className="tabulator-sm"
          columns={columns}
          data={renderedList}
          options={options}
        />
      </Col>
    </Row>
  );
};
